<template>
    <div class="full-height-layout fill mx-4" v-if="isAllowedTo('updatePermissions')">
        <mercur-card class="full-height-layout fill form">
            <h1 class="font-weight-light my-0">
                <span v-if="isEdit">Edit permission {{ originalData.permission.permissionName }}</span>
                <span v-else>Add permission</span>
            </h1>
            <div class="fill">
                <div class="row">
                    <div class="col-6">
                        <mercur-input v-model="$v.form.permissionName.$model" required :disabled="loading" :class="{'form-invalid': $v.form.permissionName.$error}">
                            Permission name
                            <template #note>
                                <span class="form-error" v-if="!$v.form.permissionName.required">Permission name is required</span>
                            </template>
                        </mercur-input>
                    </div>
                    <div class="col-12">
                        <mercur-spinner v-if="isFetchingActions"></mercur-spinner>
                        <template v-else>
                            <div class="row">
                                <div class=col-8>
                                    <h3 class="font-weight-light">Actions</h3>
                                </div>
                                <div class="col-4">
                                    <mercur-input v-if="filteredActions.length" v-model="searchPhrase">
                                        <template slot="default">Search actions</template>
                                        <template slot="suffix"><i class="fas fa-search"></i></template>
                                    </mercur-input>
                                </div>
                            </div>
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Permission override</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="action in filteredActions" :key="action.actionId">
                                    <td>{{ action.actionName }}</td>
                                    <td>
                                        <mercur-checkbox v-model="action.actionStatus" :id="`action-${action.actionStatus}`">Is allowed</mercur-checkbox>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <mercur-button @click.native="submit" :disabled="$v.$invalid || loading" class="btn btn-yellow btn-raised text-uppercase">Submit</mercur-button>
            </div>
        </mercur-card>
    </div>

    <p class="permission-message" v-else>Not allowed to see this view</p>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import CONFIG from '@root/config'

export default {
    name: 'AddEditPermission',
    mixins: [ validationMixin ],
    data () {
        return {
            loading: false,
            actions: [],
            isFetchingActions: false,
            originalData: {},
            searchPhrase: '',
            form: {
                permissionName: null,
                actions: {},
            },
        }
    },
    validations: {
        form: {
            permissionName: { required },
        },
    },
    computed: {
        isEdit () {
            return !!this.$route.params.permissionId
        },
        selectedActions () {
            let arr = []
            this.actions.forEach((item) => {
                if (item.actionStatus) {
                    arr.push(item.actionId)
                }
            })
            return arr
        },
        filteredActions () {
            if (!this.searchPhrase) {
                return this.actions
            }
            const search = this.searchPhrase.toLowerCase().trim()
            return this.actions.filter(action => action.actionName.toLowerCase().indexOf(search) > -1)
        },
    },

    methods: {
        submit () {
            if (!this.isAllowedTo('updatePermissions')) {
                return
            }

            this.form.actions.actionIds = this.selectedActions
            let url = CONFIG.API.ROUTES.ACCESS_CONTROL.PERMISSIONS.ADD

            if (this.isEdit) {
                url = CONFIG.API.ROUTES.ACCESS_CONTROL.PERMISSIONS.UPDATE
            }

            this.loading = true
            this.$api.post(url, this.form).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Permission data was saved',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'AccessControlPermissions',
                    })
                }, 500)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: `Saving data failed. Please try again.`,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        fetchPermission () {
            if (!this.isEdit || !this.isAllowedTo('updatePermissions')) {
                return
            }

            const url = CONFIG.API.ROUTES.ACCESS_CONTROL.PERMISSIONS.GET.replace('{permissionId}', this.$route.params.permissionId)
            this.loading = true
            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                this.originalData = data.data
                this.form = JSON.parse(JSON.stringify(this.originalData.permission))
                this.actions = JSON.parse(JSON.stringify(this.originalData.actions))
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        fetchActions () {
            if (!this.isAllowedTo('updatePermissions')) {
                return
            }

            const url = CONFIG.API.ROUTES.ACCESS_CONTROL.ACTIONS.OVERVIEW
            this.isFetchingActions = true
            this.$api.get(url).then(({ data }) => {
                this.actions = data.data
            }).finally(() => {
                this.isFetchingActions = false
            })
        },
    },
    created () {
        if (!this.isEdit) {
            this.fetchActions()
        }
        this.fetchPermission()
    },
}
</script>

<style lang="scss" scoped>
    /deep/ h3 {
        font-size: 24px;
    }

    .form {
        max-width: 900px;
        &__content {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
</style>
